import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import CircularNavButton from "./circularNavButton"
import NavButton from "../components/navButton"
import Markdown from "react-markdown"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"

import hearthWrapper from "../assets/footer-hearth.png"
import lightLogo from "../assets/light-logo.svg"

import { useIntl } from "gatsby-plugin-react-intl"

const Footer = () => {
  const intl = useIntl()
  const { strapiFooter } = useStaticQuery(
    graphql`
      query {
        strapiFooter {
          company_name
          company_address
          call_center
          contact
          data_administrator
          rights_reserved
          page_links {
            title
            link
          }
          document_links {
            title
            link
          }
        }
      }
    `
  )

  return (
    <>
      <footer className="page-footer">
        <Container>
          <Box position="relative">
            <img
              className="hearth-wrapper"
              src={hearthWrapper}
              alt="Footer hearth wrapper"
            ></img>
            <Box position="relative">
              <Box py={6}>
                <Grid container>
                  <Grid item className="banner-left" xs={12}>
                    <img
                      src={lightLogo}
                      width="250px"
                      alt="Carebits logo"
                    ></img>
                  </Grid>
                </Grid>
              </Box>
              <Grid container>
                <Grid container md={6} justify="space-between">
                  <Grid item className="banner-left" md={6}>
                    <p>{strapiFooter.data_administrator}</p>
                    <CircularNavButton
                      text={intl.formatMessage({
                        id: "footer_btnOurTeam",
                        defaultMessage: "O nas",
                      })}
                      link="/our-team"
                      className="white"
                    ></CircularNavButton>
                  </Grid>

                  <Grid item className="banner-left" md={5}>
                    <p>{strapiFooter.company_name}</p>
                    <Markdown allowDangerousHtml>
                      {strapiFooter.company_address}
                    </Markdown>
                    <b>
                      <p>
                        {strapiFooter.call_center}
                        <br></br>
                        {strapiFooter.contact}
                      </p>
                    </b>
                    <Box mt={3}>
                      <NavButton
                        text={intl.formatMessage({
                          id: "footer_btnContact",
                          defaultMessage: "Skontaktuj się",
                        })}
                        link="/contact"
                        className="primary"
                      ></NavButton>
                    </Box>
                  </Grid>
                </Grid>

                <Box display={{ xs: "none", sm: "contents" }}>
                  <Grid item className="banner-left" xs={2}>
                    <ul className="links-list">
                      {strapiFooter.page_links.map((element, index) => {
                        return (
                          <li>
                            <Link to={element.link}>{element.title}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Grid>
                </Box>
                <Grid item className="banner-left" xs={12} md={2}>
                  <ul className="links-list">
                    {strapiFooter.document_links.map((element, index) => {
                      return (
                        <li>
                          <a href={element.link} target="_blank">
                            {element.title}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item className="banner-left" xs={12}>
                  <Box py={6}>
                    <p>{strapiFooter.rights_reserved}</p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </footer>
    </>
  )
}

export default Footer
