import React from "react"
import PropTypes from "prop-types"

const NavButton = ({ text, link, className }) => {
  return (
    <>
      <a className={"box-nav-btn " + className} href={link}>
        {text}
      </a>
    </>
  )
}

NavButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
}

NavButton.defaultProps = {
  text: ``,
  link: `/`,
  className: "primary",
}

export default NavButton
