import React from "react"
import PropTypes from "prop-types"

const CircularNavButton = ({ text, link, className }) => {
  return (
    <>
      <a className={"circular-nav-btn " + className} href={link}>
        {text}
        <svg height="100px" width="100px">
          <g>
            <line x1="30" x2="60" y1="50" y2="50"></line>
            <polyline points=" 55,45 60,50 55,55"></polyline>
            <circle cx="50" cy="50" r="30.5"></circle>
          </g>
        </svg>
      </a>
    </>
  )
}

CircularNavButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
}

CircularNavButton.defaultProps = {
  text: ``,
  link: `/`,
  className: `primary`,
}

export default CircularNavButton
